jQuery.lib_flickity = function(callback) {
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function() {
            Flickity.createMethods.push('_createPrevNextCells');

            Flickity.prototype._createPrevNextCells = function() {
                this.on( 'select', this.setPrevNextCells );
            };

            Flickity.prototype.setPrevNextCells = function() {
                // remove classes
                changeSlideClasses( this.previousSlide, 'remove', 'is-previous' );
                changeSlideClasses( this.nextSlide, 'remove', 'is-next' );
                // set slides
                this.previousSlide = this.slides[ this.selectedIndex - 1 ];
                this.nextSlide = this.slides[ this.selectedIndex + 1 ];
                // add classes
                changeSlideClasses( this.previousSlide, 'add', 'is-previous' );
                changeSlideClasses( this.nextSlide, 'add', 'is-next' );
            };

            function changeSlideClasses( slide, method, className ) {
                if ( !slide ) {
                    return;
                }
                slide.getCellElements().forEach( function( cellElem ) {
                    cellElem.classList[ method ]( className );
                });
            }

            Flickity.prototype._createResizeClass = function() {
                this.element.classList.add('flickity-resize');
            };

            Flickity.createMethods.push('_createResizeClass');

            var resize = Flickity.prototype.resize;
            Flickity.prototype.resize = function() {
                this.element.classList.remove('flickity-resize');
                resize.call( this );
                this.element.classList.add('flickity-resize');
            };

            if (callback) {
                cssLoaded(function () {
                    callback();
                });
            }
        });
    }
};