$.lui("comp",".comp_articles_carousel",function (selector) {
    $.lib_flickity(function(){
        let slider = selector.find("[data-slider]"),
            children = slider.children();

        slider.flickity({
            imagesLoaded: false,
            percentPosition: false,
            setGallerySize: true,
            pageDots: false,
            wrapAround: false,
            contain: true,
            prevNextButtons: true,
            cellAlign: "left",
            watchCSS: children.length < 3,
        });

        let flkty = slider.data("flickity");
        let selectedIndex = flkty.selectedIndex;

        slider.on("settle.flickity", function() {
            if ( flkty.selectedIndex !== selectedIndex ) {
                selectedIndex = flkty.selectedIndex;
            }
        });

        slider.on("change.flickity",function (event, index) {
            if(win.width() > 768){
                if(index === flkty.slides.length - 2 && selectedIndex !== flkty.slides.length - 1) {
                    slider.flickity("select",flkty.slides.length - 1)
                }
                if(selectedIndex === flkty.slides.length - 1) {
                    slider.flickity("select",flkty.slides.length - 3)
                }
            }
        });
    });
});