jQuery.lui = function(n,s,callback) {
    s = $.extend($(s),{"selector":s});

    (function fn(name, selector) {
        if (selector.length) {
            callback($(selector))
        }

        if (name.indexOf("lib") !== -1) {
            if (typeof window.lui["lib"][name] === "undefined") {
                window.lui["lib"][name] = fn;
                window.lui["lib"][name].selector = selector.selector;
            }
        } else {
            // TODO tohle dělá problémy
            if (typeof window.lui[name][selector.selector] === "undefined") {
                window.lui[name][selector.selector] = fn;
                window.lui[name][selector.selector].selector = selector.selector;
            }
        }
    })(n,s);
};