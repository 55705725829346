$.lui("part",".part_tabs",function (selector) {
    bodyLoaded(function () {
        $(selector).each(function () {
            let el = $(this),
                highlight = el.find(".elm_highlight");

            fn_nav_active();

            el.on("click",".elm_tab",function () {
                el.find(".state--active").removeClass("state--active");
                $(this).addClass("state--active");
                fn_nav_active();
            });

            win.on("resize",debounce(function () {
                fn_nav_active();
            },100));

            function fn_nav_active(){
                let active = el.find(".elm_tab.state--active"),
                    active_index = active.closest(".col").index(),
                    left = active.position().left + el.scrollLeft();

                highlight.css({
                    "width": active.outerWidth(),
                    "-webkit-transform" : "translateX(" + left + "px)",
                    "-moz-transform"    : "translateX(" + left + "px)",
                    "-ms-transform"     : "translateX(" + left + "px)",
                    "-o-transform"      : "translateX(" + left + "px)",
                    "transform"         : "translateX(" + left + "px)"
                });
                highlight.find("span").css("opacity",0).eq(active_index).css("opacity",1);
                el.animate({
                    scrollLeft: left
                }, 600);
            }
        });
    });
});