let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.2/dist/flickity.pkgd.min.js",
    "parallax": "https://cdn.jsdelivr.net/npm/parallax-js@3.1.0/dist/parallax.min.js",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.11/dist/js/lightgallery-all.min.js",
    "datepicker": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/datepicker.min.js",
    "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.LANG.min.js",
    "datepicker_css": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/css/datepicker.min.css",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
};
