$.lui("comp",".comp_instagram_carousel",function (selector) {
    $.lib_flickity(function(){
        let slider = selector.find("[data-slider]");

        slider.flickity({
            imagesLoaded: false,
            percentPosition: false,
            setGallerySize: true,
            pageDots: false,
            wrapAround: true,
            contain: true,
            prevNextButtons: true,
            cellAlign: "left"
        })
    });
});