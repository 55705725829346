$.lui("part", ".part_form_contact", function(selector) {
    let dp_input = selector.find(".type--datepicker").find("input").first(),
        dp_alt = dp_input.closest(".type--datepicker").find("[type=\"hidden\"]"),
        dp = "",
        minDate = new Date(),
        maxDate = new Date();

    maxDate.setFullYear(new Date().getFullYear() + 1);

    $.importStyle(cdnjs.datepicker_css);
    $.importScript(cdnjs.datepicker, function () {
        $.importScript(cdnjs.datepicker_lang.replace("LANG", lang), function(){
            dp = dp_input.datepicker({
                language: lang,
                minDate: minDate,
                maxDate: maxDate,
                altField: dp_alt,
                altFieldDateFormat: "yyyy-mm-dd",
                autoClose: true,
                onShow: function () {
                    doc.find(".datepickers-container").addClass("state--active");
                    dp_input.closest(".type--datepicker").addClass("state--focus");
                },
                onHide: function () {
                    doc.find(".datepickers-container").removeClass("state--active");
                    dp_input.closest(".type--datepicker").removeClass("state--focus");
                },
            }).data('datepicker');
            dp.selectDate(minDate);
        })
    })
});