var reInitGCaptcha;

(function($) {
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LclKJwUAAAAAOrlAbPCVsScqtaLefvqnVediXkY").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LclKJwUAAAAAOrlAbPCVsScqtaLefvqnVediXkY', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header', '#layout_footer']
        });

        swup.on('clickLink', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $('html').css('overflow','visible');
            if ($(".lib--dialog").length) {
                nl_lib_dialog.close();
            }
        });

        swup.on('contentReplaced', function () {
            const content = new DOMParser().parseFromString(swup.cache.last.originalContent, 'text/html')

            content.head.querySelectorAll('[data-lib-swup-replace]').forEach(element => {
                const tag = document.querySelector('[data-lib-swup-replace="'+element.dataset.libSwupReplace+'"]')

                if (tag !== null) {
                    tag.outerHTML = element.outerHTML
                } else {
                    document.head.insertAdjacentHTML('beforeend', element.outerHTML)
                }
            });

            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",$(key))
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",$(key))
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",$(key))
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });
            });

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (typeof window.seznam_retargeting_id !== "undefined") {
                $.getScript("https://c.imedia.cz/js/retargeting.js");
            }

            if(typeof window.luiCookieConsentInit !== 'undefined') {
                window.luiCookieConsentInit()
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }
        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
        let bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

    lui_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s).length) {
                window.lui.lib[key](key,dialog_selector.find(s))
            }
        });

        Object.keys(window.lui.part).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");

            if (dialog_selector.find($(key)).length) {
                window.lui.part[key]("part",dialog_selector.find($(key)))
            }
        });
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_gallery", "[data-lib-lightgallery]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--lightgallery-item").length) {
                $.importScript(cdnjs.lightgallery, function(){
                    $(selector).each(function(){
                        $(this).lightGallery({
                            thumbnail: true,
                            selector: ".lib--lightgallery-item",
                            exThumbImage: 'data-exthumbimage',
                            fullScreen: false,
                            zoom: true,
                            actualSize: false,
                            hash: false,
                            download: true,
                            autoplay: false,
                            autoplayControls: false
                        });
                    });
                });
            }
        });
    });

    $.lui("lib_parallax_move","[data-lib-parallax-move], .lib--parallax-move",function (selector) {
        bodyLoaded(function () {
            $.importScript(cdnjs.parallax,()=>{
                let params = null;
                if (win.width() < 600) {
                    params = {
                        scalarX: 24,
                        scalarY: 24
                    };
                }

                selector.each(function () {
                    let scene = $(this).get(0);
                    let parallax = new Parallax(scene,params);
                });
            });
        })
    });

    $.lui("lib_captcha", "[data-recaptcha]", function() {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            var submitButton = $(this).find('button[type="submit"]');
            if(submitButton.length) {
                submitButton.attr('disabled','disabled');
                submitButton.addClass('mod--loading');
            }
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }

                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }
                });
                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();
})(jQuery);
