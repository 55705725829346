$.lui("comp", ".comp_visual", function(selector){
    $.lib_flickity(function(){
        let slider = selector.find("[data-visual-slider]");

        slider.flickity({
            imagesLoaded: false,
            percentPosition: false,
            setGallerySize: false,
            pageDots: true,
            wrapAround: true,
            contain: true,
            prevNextButtons: true
        });

        slider.find(".flickity-page-dots").appendTo(selector.find(".elm_foot_dots"));
        slider.find(".flickity-button").lui_ripple();

        selector.on("click","[data-scroll]",function () {
            if(selector.next("section").length) {
                selector.next("section").anchor_anim(500);
            }
        })
    });
});